import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center" >
      <div  style={{width: "100%"}}>
        <div className={`section-title ${props.isSegmi && "segmi"}`}>
          <h2>Galería</h2>
        </div>
        <div
            className="row"
        >
          <div
              className="portfolio-items"
               style={{
                   margin: "auto",
                   display: "flex",
                   flexFlow: "row wrap",
                   justifyContent: "center",
                   alignItems: "flex-start"

               }}
          >
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
