import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro" style={{
          backgroundImage: `${props.isSegmi ? "url(../img/portfolio/05-portfolio-segmi.jpeg)" : "url(../img/dsc03355-crop-u2204.jpg)"}`
      }}>
        <div className="overlay">
          <div>
            <div className="row">
                <div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between"
                    }}
                  >
                    <div
                        style={{
                          paddingTop: "350px",
                          paddingBottom: "200px",
                          textAlign: "center",
                            width: "100%"
                          }}>
                        <h1>{props.data.title}</h1>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
