import React from "react";
import {useNavigate} from "react-router-dom";

export const PageSelector = (props) => {
    const navigate = useNavigate();

    return (
        <div className={"page-selector"}>
            <div className={"selector-container"}>
                <div
                    className={"segundamilla selector"}
                    onClick={() => {
                        navigate("/segunda-milla")
                    }}
                >
                    <div className={"overlay"}/>
                </div>
                <div className="text">
                    <h1>
                    Segunda milla
                    </h1>
                </div>
            </div>
            <div className={"selector-container"}>
                <div
                    className={"segmi selector"}
                    onClick={() => {
                        navigate("/segmi")
                    }}
                >
                    <div className={"overlay"}/>
                </div>
                <div className="text">
                    <h1>
                        Segmi
                    </h1>
                </div>
            </div>
        </div>
    )
}
