import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="clients">
      <div className="container" style={{width: "100%"}}>
        <div className={`section-title text-center ${props.isSegmi && "segmi"}`}>
          <h2>Nuestros Clientes</h2>
        </div>
        <div className="row" style={{
            display: "flex",
            justifyContent: "center",
            flexFlow: "row wrap",
            gap:"16px"
        }}>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`}>
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
