import React from "react";

export const Modal = (props) => {
  return (
    <div className="transparent-background-modal" onClick={props.handleClickOnBackground}>
      <div className="modal-window" onClick={props.handleClickOnBackground}>
        <div className="modal-container">
          {props.children}
        </div>
      </div>
    </div>
  );
};
