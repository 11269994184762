import React, { useState } from "react";
import { Modal } from "./Modal";

export const Image = ({title, smallImage}) => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <div>
            {openModal &&
                <Modal handleClickOnBackground={() => setOpenModal(false)}>
                    <img src={smallImage} className="img-responsive" alt={title} />{" "}
                </Modal>
            }
            <div className="portfolio-item">
               <div className="hover-bg" onClick={() => setOpenModal(true)}>
                   <div className="hover-text">
                     <h4>{title}</h4>
                   </div>
                   <img src={smallImage} className="img-responsive" alt={title} />{" "}
               </div>
            </div>
        </div>
    );
};
