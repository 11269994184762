import React from "react";
import JsonSegundamilla from "./data/data-segundamilla.json";
import JsonSegmi from "./data/data-segmi.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import {PageSelector} from "./components/PageSelector";
import {Routes, Route} from "react-router-dom";
import {Main} from "./components/main";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {

  return (
    <div >
        <Routes>
            <Route
                path={"/"}
                element={<PageSelector/>}
            />
            <Route
                path={"/segmi"}
                element={<Main data={JsonSegmi} isSegmi={true}/>}
            />
            <Route
                path={"/segunda-milla"}
                element={<Main data={JsonSegundamilla} isSegmi={false}/>}
            />
        </Routes>
    </div>
  );
};

export default App;
