import React from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { Contact } from "./contact";
export const Main = (props) => {
  return(
    <div>
      <Navigation data={props.data.Header} isSegmi={props.isSegmi}/>
      <Header data={props.data.Header} isSegmi={props.isSegmi}/>
      <About data={props.data.About} isSegmi={props.isSegmi}/>
      <Services data={props.data.Services} isSegmi={props.isSegmi}/>
      <Gallery data={props.data.Gallery} isSegmi={props.isSegmi}/>
      <Testimonials data={props.data.Testimonials} isSegmi={props.isSegmi}/>
      <Contact data={props.data.Contact} isSegmi={props.isSegmi}/>
    </div>
  )
}

